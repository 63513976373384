import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18next from "../../../utils/locales";
import { RouteComponentProps as RouteProps } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ServiceFactory from "../../../utils";
import * as Yup from "yup";
import { useFormik } from "formik";

const Login = (props: RouteProps) => {
  const auth = ServiceFactory.getAuthService();
  const initialValues = {
    email: "",
    password: "",
  };
  const [authError, setAuthError] = useState<string>("");
  const [clicked, setClicked] = useState<boolean>(false);

  const onSubmit = () => {
    setClicked(true);
    auth
      .login({
        email: `${formik.values.email}@ayna.gov.az`,
        password: `${formik.values.password}`,
      })
      .then((res) => {
        if (res.data["message"]) {
          // setAuthError(res.data["message"]);
          // To make disappear server error message uncomment below line
          // setTimeout(() => setAuthError(""), 5000);
        }
      })
      .catch((e) => {
        // API error response parsing
        if (e.response && e.response.data && e.response.data.errors) {
          const errorMessage = e.response.data.errors[0]?.message;
          setAuthError(errorMessage);
        } else {
          setAuthError("An unexpected error occurred.");
        }
        setClicked(false);
      });
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .required(i18next.t("login_page:login_alerts:email_required"))
      .matches(
        /^[a-zA-Z0-9.]*$/,
        i18next.t("login_page:login_alerts:email_policy")
      ),
    password: Yup.string().required(
      i18next.t("login_page:login_alerts:password_required")
    ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCardGroup>
              <CCard className="p-4 border border-info">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1>{i18next.t("login_page:title")}</h1>
                    <p className="text-muted">
                      {i18next.t("login_page:title_label")}
                    </p>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        className="p-2"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        type="text"
                        placeholder={i18next.t("login_page:email")}
                        autoComplete="email"
                      />

                      <CInputGroupAppend>
                        <CInputGroupText>{"@ayna.gov.az"}</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.email ? formik.errors.email : ""}
                    </p>

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        type="password"
                        placeholder={i18next.t("login_page:password")}
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <p style={{ color: "red" }}>
                      {" "}
                      {formik.errors.password ? formik.errors.password : ""}
                    </p>

                    <p style={{ color: "red" }}>
                      {" "}
                      {authError
                        ? i18next.t(`login_page:login_alerts:${authError}`)
                        : ""}
                    </p>
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          type="submit"
                          className="px-4"
                          disabled={clicked}
                        >
                          {i18next.t("login_page:login_button")}
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <Link to="/forgot-password">
                          <CButton color="link" className="px-0">
                            {i18next.t("login_page:forgot_password")}
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
