import { Icon } from "leaflet";
import busSVG from "./icons/stop.svg";
import buildingSVG from "./icons/station.svg";
import aSVG from "./icons/a.svg";
import bSVG from "./icons/b.svg";
import blueBusSVG from "./icons/blue-stop.svg";
import blueBuildingSVG from "./icons/blue-station.svg";
import blueAutostationSVG from "./icons/blue-autostation.svg";
import autostationSVG from "./icons/autostation.svg";
import gasStationSVG from "./icons/gas-station.svg";
import bridgeSVG from "./icons/bridge.svg";
import i18n, { TFunction } from "i18next";
import { CBadge } from "@coreui/react";
import { FieldArrayRenderProps } from "formik";
import firstStageLogic from "../helpers/logic/firstStage";
import moment from "moment";

export interface IProps {
  isEdit: boolean;
  id?: number;
}

export interface ToRemoteFirstStage {
  code: string;
  type_id: RouteType;
  cycle_type_id: RouteCycleType;
  name: string;
  headway: number;
  start_loc_name: string;
  end_loc_name: string;
  start_time: string;
  end_time: string;
  branch_name:string;
  intertrip_stop_time: number;
  parking_time:number;
  route_feature_id: string;
  service_type_id: RouteServiceType;
  on_weekdays: boolean;
  on_weekends: boolean;
  branch_feature_id: string;
  safe_speed_limit: number;
  road_desc: string;
  price: number;
  distance:number,
  duration:number,
  terminal_departure_time_desc: string;
  initial_stop_arrival_time_desc: string;
  intertrip_stop_time_desc: string;
  intertrip_stop_location_desc: string;
  driver_break_time_desc: string;
  bus_crew_change_desc: string;
  terminal_arrival_time_desc: string;
  streets: NewRouteStreetData;
}

export interface FromRemoteFirstStage
    extends Omit<ToRemoteFirstStage, "streets"> {
  streets: {
    sequence: number;
    street_name: string;
    street_desc: string;
  }[];
}

export interface IStopSelectedData {
  featureGeometry: string,
  featureId: string,
  featureTypeId: number,
  property: {
    Department: string,
    End_Point: string,
    reverse_route: string,
    Name: string,
    Number: string,
    ShapeLngth: number | string,
    Start_point: string
  }
  stops: {
    distanceToNextStop: number,
    featureGeometry: string,
    featureId: string,
    featureTypeId: number,
    property: {
      Admin: string,
      City: string,
      Country: string,
      Department: string,
      Name: string,
      TicketMach: string,
      Type: string | number
    }
  }[]
  value: string,
}

export interface FirstStageState {
  name: string;
  intertrip_stop_time: number;
  buses: {
    id: number,
    reg_plate: string
  }[];
  trip_groups:ITripGroup[];
  trips:{
    arrival_time: string,
    departure_time: string,
    direction_id:number,
    sequence: number,
    stop_times: {
      arrival_time: string,
      departure_time: string,
      stop_id: string,
      stop_sequence: number,
    }[]
  }[];
  start_name: string;
  branch_feature_id: string;
  end_name: string;
  route_feature_id: string;
  parking_time: number;
  number: string;
  featureId: string;
  headway: string;
  route: string;
  type: RouteType;
  characteristics: string;
  carriers: object;
  max_speed: string;
  fee: string;
  start_time: string;
  end_time: string;
  branch_name: string;
  stop_select_data: IStopSelectedData,
  route_days: RouteDays[];
  route_months: RouteServiceType;
  is_round: boolean;
  leg1_distance?: string;
  leg1_duration_hh?: string;
  leg1_duration_mm?: string;
  leg2_distance?: string;
  leg2_duration_hh?: string;
  leg2_duration_mm?: string;
  round_distance?: string;
  round_duration_hh?: string;
  round_duration_mm?: string;
  terminal_departure_time_desc: string;
  initial_stop_arrival_time_desc: string;
  intertrip_stop_time_desc: string;
  intertrip_stop_location_desc: string;
  driver_break_time_desc: string;
  bus_crew_change_desc: string;
  terminal_arrival_time_desc: string;
  street_details: {
    id: number;
    street_name: string;
    street_info: string;
  }[];
}

export interface SecondStageState {
  branch_name: string;
  bus_crew_change_desc: string;
  buses: any[];
  code: string;
  created_at: string;
  created_by: number;
  cycle_type_id: RouteCycleType;
  distance: number;
  driver_break_time_desc: string;
  duration: number;
  end_loc_name: string;
  end_time: string;
  headway: number;
  intertrip_stop_time: number;
  id: number;
  initial_stop_arrival_time_desc: string;
  intertrip_stop_location_desc: string;
  intertrip_stop_time_desc: string;
  name: string;
  on_weekdays: boolean;
  on_weekends: boolean;
  price: string;
  reviewed_at: string;
  reviewed_by: number;
  reviewer_full_name: string;
  road_desc: string;
  route_feature_id: string;
  safe_speed_limit: number;
  service_type_id: number;
  start_loc_name: string;
  start_time: string;
  state_id: number;
  streets: any[];
  terminal_arrival_time_desc: string;
  terminal_departure_time_desc: string;
  trips: any;
  type_id: number;
  updated_at: string;
  trip_passport_verifier_full_name: string | null;
  trip_passport_verifier_job_title: string | null;
  trip_passport_signer_full_name: string | null;
  trip_passport_signer_job_title: string | null;
  carriers: number[];
  trip_groups:ITripGroup[];
}

export interface SecondStageState {
  outbound_markers: {
    refID: number;
    id: number;
    name: string;
    distance: number;
  }[];
  circular_markers: {
    refID: number;
    id: number;
    name: string;
    distance: number;
  }[];
  inbound_markers: {
    refID: number;
    id: number;
    name: string;
    distance: number;
  }[];
}

export interface IMarker {
  id: number;
  type: number;
  position: {
    lat: number;
    lng: number;
  };
}

export const BusMarker = new Icon({
  iconUrl: busSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const AMarker = new Icon({
  iconUrl: aSVG,
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const BMarker = new Icon({
  iconUrl: bSVG,
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const TerminalMarker = new Icon({
  iconUrl: buildingSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const BlueBusMarker = new Icon({
  iconUrl: blueBusSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const BlueTerminalMarker = new Icon({
  iconUrl: blueBuildingSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const AutostationMarker = new Icon({
  iconUrl: autostationSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const BlueAutostationMarker = new Icon({
  iconUrl: blueAutostationSVG,
  className: "selected-marker",
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const BridgeMarker = new Icon({
  iconUrl: bridgeSVG,
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export const GasStationMarker = new Icon({
  iconUrl: gasStationSVG,
  iconSize: [25, 50],
  iconAnchor: [12.5, 25],
});

export type NewRouteSegmentData = [
  {
    distance: number;
    duration: number;
  },
  {
    distance: number;
    duration: number;
  }?
];

export type NewRouteCarrierData = number[];

export type NewRouteBusData = number[];

export type NewRouteCycleBusData = {
  carrier_id: number;
}[];

export type NewRouteStopTime = {
  stop_id: number;
  arrival_time: string | null;
  departure_time: string | null;
}[][][];

export type NewRouteTripGroups = {
  passport_code: string;
  active_bus_id: number;
  reserve_bus_ids: number[];
  signed_at: string;
  expires_at: string;
}[];

export type NewRouteStopData = [
  {
    stop_id: number;
    dist_traveled: number;
  }[],
  {
    stop_id: number;
    dist_traveled: number;
  }[]?
];

export type NewRouteStreetData = {
  name: string;
  desc: string;
}[];

export enum Direction {
  Outbound = 1,
  Inbound,
}


export enum BusState {
  Archived = 1,
  Published = 2,
}

export enum CarrierState {
  Archived = 1,
  Published = 2,
}

export enum RouteState {
  Pending = 1,
  Published,
  Archived,
}

export enum DraftRouteState {
  Draft = 1,
  InReview = 2,
  Reviewed = 3,
  Approved = 4,
}

export enum RouteDays {
  Weekdays = 1,
  Weekends,
}

export enum RouteType {
  IntraCity=1,
  IntraDistrict ,
  Intercity,
  International,

}

export enum RouteServiceType {
  Regular = 1,
  Seasonal,
}

export enum RouteCycleType {
  Linear = 1,
  Circular,
}

export enum Stages {
  FirstStage = 1,
  SecondStage,
  ThirdStage,
}

export enum MapModes {
  Station = 1,
  Bus,
  InvisiblePoint,
}

export const formDraftRouteState = (id: DraftRouteState, name: string) => {
  if (id === DraftRouteState.Approved) {
    return (
        <td>
          <CBadge style={{ color: "white" }} color="success">
            {name}
          </CBadge>
        </td>
    );
  }
  if (id === DraftRouteState.Draft) {
    return (
        <td>
          <CBadge style={{ color: "white" }} color="danger">
            {name}
          </CBadge>
        </td>
    );
  }
  if (id === DraftRouteState.InReview) {
    return (
        <td>
          <CBadge style={{ color: "white" }} color="info">
            {name}
          </CBadge>
        </td>
    );
  }
  if (id === DraftRouteState.Reviewed) {
    return (
        <td>
          <CBadge color="warning">{name}</CBadge>
        </td>
    );
  }
};

export const formatBusState = (id: BusState) => {
  if (id === BusState.Published) {
    return (
      <td>
        <CBadge style={{ color: "white" }} color="success">
          {"Təsdiqlənmiş"}
        </CBadge>
      </td>
    );
  }
  if (id === BusState.Archived) {
    return (
      <td>
        <CBadge style={{ color: "white" }} color="dark">
          {"Arxivləşdirilmiş"}
        </CBadge>
      </td>
    );
  }
};

export const formatState = (id: RouteState, name: string) => {
  if (id === RouteState.Pending) {
    return (
        <td>
          <CBadge color="warning">{name}</CBadge>
        </td>
    );
  }
  if (id === RouteState.Published) {
    return (
        <td>
          <CBadge style={{ color: "white" }} color="success">
            {name}
          </CBadge>
        </td>
    );
  }
  if (id === RouteState.Archived) {
    return (
        <td>
          <CBadge style={{ color: "white" }} color="dark">
            {name}
          </CBadge>
        </td>
    );
  }
};

export const formatType = (id: RouteType, name: string) => {
  if (id === RouteType.IntraCity ) {
    return (
        <td>
          <CBadge color="info">{name}</CBadge>
        </td>
    );
  }
  if (id === RouteType.International) {
    return (
        <td>
          <CBadge color="danger">{name}</CBadge>
        </td>
    );
  }
  if(id === RouteType.Intercity) {
    return (
      <td>
        <CBadge color="dark">{name}</CBadge>
      </td>
    )
  }
  if (id === RouteType.IntraDistrict ) {
    return (
        <td>
          <CBadge color="success">{name}</CBadge>
        </td>
    );
  }
};

export const formatCycleType = (id: RouteCycleType, name: string) => {
  if (id === RouteCycleType.Circular) {
    return (
        <td>
          <CBadge color="info">{name}</CBadge>
        </td>
    );
  }
  if (id === RouteCycleType.Linear) {
    return (
        <td>
          <CBadge color="success">{name}</CBadge>
        </td>
    );
  }
};

export const formatServiceType = (id: RouteServiceType, name: string) => {
  if (id === RouteServiceType.Regular) {
    return (
        <td>
          <CBadge color="info">{name}</CBadge>
        </td>
    );
  }
  if (id === RouteServiceType.Seasonal) {
    return (
        <td>
          <CBadge color="success">{name}</CBadge>
        </td>
    );
  }
};

export const tableFields = (t: TFunction) => [
  {
    key: "order",
    label: t("common_table_props:order"),
    _style: { width: "3%" },
  },
  { key: "id", label: t("all_routes:id"), _style: { width: "3%" } },
  { key: "branch_name", label: t("all_routes:branch_name") },
  { key: "name", label: t("all_routes:name"), _style: { width: "20%" } },
  { key: "code", label: t("all_routes:code") },
  // { key: "end_time", label: t("all_routes:arr_time") },
  // { key: "start_time", label: t("all_routes:dep_time") },
  { key: "price", label: t("all_routes:price") },
  { key: "cycle_type", label: t("all_routes:cycle_type") },
  { key: "road_desc", label: t("all_routes:road_desc") },
  { key: "safe_speed_limit", label: t("all_routes:safe_speed_limit") },
  { key: "service_type", label: t("all_routes:service_type") },
  { key: "type", label: t("all_routes:type") },
  { key: "duration", label: t("all_routes:duration") },
  { key: "distance", label: t("all_routes:distance") },
  { key: "headway", label: t("all_routes:headway") },
  {
    key: "start_loc_name",
    label: t("all_routes:start_loc_name"),
  },
  {
    key: "end_loc_name",
    label: t("all_routes:end_loc_name"),
  },
  { key: "state", label: t("all_routes:state"), _style: { width: "5%" } },
];

export const defaultFields = [
  "order",
  "name",
  "id",
  "start_loc_name",
  "end_loc_name",
  "code",
  "branch_name",
  // "arrival_time",
  // "departure_time",
  "duration",
  "distance",
  "price",
  "state",
];

export function getType(t: RouteType | number) {
  switch (t) {
    case RouteType.IntraCity:
      return i18n.t("route:city");
    case RouteType.IntraDistrict:
      return i18n.t("route:regional");
    case RouteType.Intercity:
      return i18n.t("route:intercity");
    case RouteType.International:
      return i18n.t("route:international");
    default:
      return i18n.t("route:unknown");
  }
}

export function getRouteMonthType(t: RouteServiceType | number) {
  switch (t) {
    case RouteServiceType.Regular:
      return i18n.t("route:regular");
    case RouteServiceType.Seasonal:
      return i18n.t("route:seasonal");
    default:
      return i18n.t("route:unknown");
  }
}
export interface INewRouteIdList{
  bus: number,
  expires_at: any,
  name: string,
  reserve_buses: any,
  signed_at: string,
  tripGroupID: number,
  trips: any
}

export interface ITripGroup {
  tripGroupID: number;
  sequence: number;
  active_bus_brand_name:string;
  active_bus_carrier_id: number;
  active_bus_carrier_name: string;
  active_bus_id: number;
  active_bus_reg_plate: string;
  passport_code: string | number;
  name: string;
  bus: number;
  reserve_buses: number[] | IReserveBus[];
  signed_at: string;
  expires_at: string;
  active_bus_model_name?: string;
  trips: {
    stop_id: number;
    stop_name: string;
    departure: string | number;
    arrival: string | number;
  }[];
}

export interface IReserveBus {
  id: number;
  carrier_id: number;
  reg_plate: string;
  reverse_bus_carrier_name: string
}

export const addNewRow = (helpers: FieldArrayRenderProps, ttID: number) => {
  const today = moment().format("YYYY-MM-DD");
  const monthFromToday = moment().add(1, "month").format("YYYY-MM-DD");
  const busCardNumber =  Math.floor(Math.random() * 900000) + 100000;
  const firstState = firstStageLogic.getInstance().getState();
  if (firstState?.stop_select_data && firstState?.stop_select_data.stops) {
    const stopTimes = firstState?.stop_select_data.stops.map(m => ({
      arrival: "",
      departure: "",
      stop_name: m.property.Name,
      stop_id: m.featureId,
    }))
    helpers.push({
      tripGroupID: ttID,
      passport_code: busCardNumber.toString(),
      bus: "",
      reserve_buses: [],
      signed_at: today,
      expires_at: monthFromToday,
      trips: stopTimes,
    });
  }
};


