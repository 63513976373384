import dayjs from "dayjs";
import ObjectToExcel from "../helpers/excel";
import { CarrierType } from "../types";
import { ApiService } from "./types";
import {
  AddCarrierRequest,
  EditCarrierRequest,
} from "./types/requestTypes/Carrier";
import { EmptyResponse } from "./types/responseTypes/Auth";
import {
  GetCarrierResponse,
  IAllCarriersForExport,
  IAllCarriersWithoutPagination,
  ICreateCarrier,
} from "./types/responseTypes/Carrier";
class CarrierService extends ApiService {
  public async exportCarriersToExcel() {
    const { data } = await this.getAllCarriersForExport();
    data.forEach((c) => {
      c.created_at = dayjs(c.created_at).format("DD/MM/YYYY HH:mm:ss");
      c.type = this.getType(c.type as CarrierType);
    });
    ObjectToExcel(data, "carrier");
  }

  public async archiveCarrier(id: number) {
    const { data } = await this.axios.put<any>(
      `/carriers/${id}/archive`,
      {}
    );
    return data;
  }

  public async unarchiveCarrier(id: number) {
    const { data } = await this.axios.put<any>(
      `/carriers/${id}/unarchive`,
      {}
    );
    return data;
  }

  public async getAllCarriersWithoutPagination() {
    const res = await this.axios.get<IAllCarriersWithoutPagination>(
      "/carriers",
      {}
    );
    return res.data;
  }

  public async getAllCarriersForExport() {
    const res = await this.axios.get<IAllCarriersForExport>(
      "/carriers/export",
      {}
    );
    return res.data;
  }

  public async getAllCarriersForNewRoute() {
    const res = await this.axios.get<IAllCarriersWithoutPagination>(
      "/carriers/brief",
      {}
    );
    return res.data;
  }

  public async getCarrier(id: number) {
    const res = await this.axios.get<GetCarrierResponse>(`/carriers/${id}`, {});
    return res.data;
  }

  public async createCarrier(data: AddCarrierRequest) {
    const res = await this.axios.post<ICreateCarrier>("/carriers", data);
    return res.data;
  }

  public async editCarrier(data: EditCarrierRequest) {
    const res = await this.axios.patch<EmptyResponse>("/carriers", data);
    return res.data;
  }

  public async deleteCarrier(id: number) {
    const res = await this.axios.delete<EmptyResponse>(`/carriers/${id}`, {});
    return res.data;
  }

  getType = (id: CarrierType) => {
    if (id === CarrierType.Legal) return "Hüquqi";
    if (id === CarrierType.Physical) return "Fiziki";
    return "Naməlum";
  };
}

export default CarrierService;
